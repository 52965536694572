import React, { useRef, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

import { API_FUNCTIONS } from "../../../config/api";

let url = API_FUNCTIONS;
//if (window.location.hostname === //"localhost") {
//  url = API_FUNCTIONS_LOCAL;
//}

export default function PaymentForm(props) {
  const {
    formField: { nameOnCard, cardNumber, expiryDate, cvv },
  } = props;
  const paypal = useRef();
  const [paidFor, setPaidFor] = useState(false);
  const [payAmount, setPayAmount] = useState("0");
  const query = new URLSearchParams(window.location.search);
  const token = query.get("i");
  const handleApprove = (orderId) => {
    setPaidFor(true);

    const requestOptions = {
      method: "GET",
    };

    fetch(
      `${url}/people/onboarding/sendEmail/${token}`,
      requestOptions
    ).then((response) => response.json());
  };

  var clientId = "0";
  var amount = "0";

  useEffect(async () => {
    // load PayPal script
    await loadPayPalSettings().then(() => {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&disable-funding=paylater`;
      script.async = true;
      document.head.appendChild(script);

      // call function to load PayPal buttons
      script.onload = () => scriptLoaded();
    });
  }, []);

  async function loadPayPalSettings() {
    // get PayPal client id
    await fetch(`${url}/settings/public/payPal/clientId`)
      .then((response) => response.json())
      .then(function(data) {
        clientId = !data.settingValue ? "" : data.settingValue;
      })
      .catch((error) => console.log(error));

    // get PayPal client id
    await fetch(`${url}/settings/public/payPal/amount`)
      .then((response) => response.json())
      .then(function(data) {
        amount = !data.settingValue ? "" : data.settingValue;
        setPayAmount(amount);
      })
      .catch((error) => console.log(error));
  }

  function scriptLoaded() {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "ITG Driver Registration",
                amount: {
                  currency_code: "USD",
                  value: amount,
                },
              },
            ],
            application_context: { shipping_preference: "NO_SHIPPING" },
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          handleApprove(data.orderID);
          console.log(order);
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment for Driver Registration Fees: ${payAmount}
      </Typography>
      <div style={{ padding: "10px" }}></div>
      <div style={{ textAlign: "center" }}>
        {paidFor == false && <div ref={paypal}></div>}
        {paidFor == true && (
          <div style={{ textAlign: "left" }}>
            <p>Payment received.Thank you.</p>
            <p>
              An email confirmation with further instructions has just been sent
              to the email address you provided. Please check your spam folder.
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
