import React from "react";
import { Typography } from "@material-ui/core";
import "antd/dist/antd.css";
import UploadDoc from "./Upload";
import List from "devextreme-react/list";
import DataSource from "devextreme/data/data_source";
import { createStore } from "devextreme-aspnet-data-nojquery";
import "./styles.module.css";

import { API_FUNCTIONS, APP_NAME_PROD } from "../../../config/api";

let url = API_FUNCTIONS;
//if (window.location.hostname === //"localhost") {
//  url = API_FUNCTIONS_LOCAL;
//}

const query = new URLSearchParams(window.location.search);
const token = query.get("i");

export default function App(props) {
  const {
    formField: { chkUpload },
  } = props;

  const dataStore = createStore({
    key: "id",
    loadUrl: `${url}/peopleOb/application/${token}?group=Onboarding`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.xhrFields = { withCredentials: false };
    },
    loadMode: "raw",
  });

  const dataSource = new DataSource({
    store: dataStore,
  });

  function OnboardingItems(item) {
    return (
      <>
        <Typography component="h6" variant="h6">
          {item.name}
        </Typography>
        <div style={{ paddingBottom: "10px" }}>
          {typeof item.hasOwnProperty("description") &&
          item.description !== undefined &&
          item.description !== null &&
          item.description !== "undefined"
            ? item.description
            : ""}
        </div>
        <UploadDoc peopleId={item.peopleId} id={item.id} name={item.name} />
      </>
    );
  }

  return (
    <>
      <div style={{ textAlign: "left", paddingTop: "10px" }}>
        <Typography component="h5" variant="h5">
          Upload photos/scans of the following documents:
        </Typography>
        <div style={{ textAlign: "left", paddingTop: "10px" }}></div>
        <hr />
        <List
          dataSource={dataSource}
          itemRender={OnboardingItems}
          height="100%"
        />
      </div>
    </>
  );
}
